import { api } from "./index";

const index = async ({ cart_token }) => {
  const resSouv = await api(`/product_souv_stocks`,  
  { cart_token: cart_token },
  {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(cart_token)
  }
  );
  return resSouv;
};

export default {
  index
};
