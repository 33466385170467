import { newApi } from "../index";

const get = async () => {
  const apiLink = `/taxons`;

  const resCategory = await newApi(apiLink, {});

  const categories = resCategory;

  return categories;
};
export default {
  get
};
