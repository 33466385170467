import { useState, useContext, createContext } from "react";

const CartContext = createContext();

export const CartProvider = ({ children, initial }) => {
  const [cart, setCart] = useState(initial);
  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
