import { api } from "./index";

const get = async (categorySlug, subcategorySlug, query) => {
  const apiLink = `/categories/${categorySlug}${
    subcategorySlug ? `/${subcategorySlug}` : ""
  }`;

  const resCategory = await api(apiLink, query);

  const { products, category, pages } = resCategory;

  return { products, category, pages };
};

const all = async () => {
  const resCategories = await api("/categories");

  const fetchedCategories = resCategories.map(category => {
    return {
      id: category.id,
      name: category.name,
      slug: category.slug,
      subcategories: category.subcategories
    };
  });

  return fetchedCategories;
};

export default {
  get,
  all
};
