import { api } from "./index";

const getByAddresses = async query => {
  const resCities = await api(`/api/addresses/index`, query);
  return resCities;
};

export default {
  getByAddresses
};
