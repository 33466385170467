import { api } from "./index";
  const store = async query => {
    const resStore = await api(`/api/request_products/store`, query);

    return resStore;
  };

export default {
  store
};
