import { api } from "./index";

const get = async (categorySlug, query) => {
  const apiLink = `/menu_categories/${categorySlug}`;

  console.log(apiLink);

  const resMenuCategories = await api(apiLink, query);

  const { products, line, pages } = resMenuCategories;

  return { products, line, pages };
};

const menu = async () => {
  const resMenuCategories = await api("/menu_categories");
 

  return resMenuCategories;
};

export default {
  get,
  menu
};
