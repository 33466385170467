import { api } from "./index";

const index = async (query) => {
  const resOrders = await api(`/api/orders/index`, query);
  return resOrders;
};

const show = async (slug, query) => {
  const resOrder = await api(`/api/orders/show/${slug}`, query);
  return resOrder;
};


export default {
  index,
  show
};
