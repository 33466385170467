import { useState, useContext, createContext } from "react";

const MenuContext = createContext();

export const MenuProvider = ({ children, initial }) => {
  const [menu, setMenu] = useState(initial || []);
  return (
    <MenuContext.Provider value={{ menu, setMenu }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
