import { api } from "./index";

const get = async (slug, query) => {
  const resProduct = await api(`/products/slug/${slug}`, query);

  return resProduct;
};

const get_sku = async (sku, query) => {
  const resProduct = await api(`/products/${sku}`, query);

  return resProduct;
};

const productCustomerTax = async (slug, query) => {
  const resProductTax = await api(`/products/product_customer_tax/${slug}`, query);

  return resProductTax;
};

const relateds = async (sku, query) => {
  const resRelateds = await api(`/products/related/${sku}`, query);

  return resRelateds;
};

const highlights = async query => {
  const resHighlights = await api(`/products/highlights`, query);

  return resHighlights;
};

const search = async query => {
  const resProducts = await api(`/products/search`, query);

  return resProducts;
};

const outlet = async query => {
  const resOutlet = await api("/products/outlet", query);

  return resOutlet;
};

const moveis = async query => {
  const resMoveis = await api("/products/moveis", query);

  return resMoveis;
};

const decor = async query => {
  const resDecor = await api("/products/decor", query);

  return resDecor;
};

const souvenirs = async query => {
  const resSouvenirs = await api("/products/souvenirs", query);

  return resSouvenirs;
};

const souvenirs_expo = async query => {
  const resSouvenirs = await api("/products/souvenirs_expo", query);

  return resSouvenirs;
};
const hotel_name = async query => {
  const resHotelName = await api("/products/hotel_name", query);

  return resHotelName;
};

const get_stock = async query => {
  const resProductStock = await api("/products/get-stock", query);

  return resProductStock;
};

const gallery = async query => {
  const resGallery = await api("/products/gallery", query);

  return resGallery;
};

const seats = async query => {
  const resSeats = await api("/products/seats", query);

  return resSeats;
};

const bookx = async query => {
  const resBookx = await api("/products/bookx", query);

  return resBookx;
};

const lancamento = async query => {
  const resLancamento = await api("/products/lancamento", query);

  return resLancamento;
};

export default {
  get,
  get_sku,
  relateds,
  highlights,
  search,
  outlet,
  moveis,
  souvenirs,
  souvenirs_expo,
  hotel_name,
  get_stock,
  decor,
  bookx,
  lancamento,
  gallery,
  seats,
  productCustomerTax
};
