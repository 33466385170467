import { useState, useContext, createContext } from "react";

const OutletContext = createContext();

export const OutletProvider = ({ children, initial }) => {
  const [outlet, setOutlet] = useState(initial);

  return (
    <OutletContext.Provider value={{ outlet, setOutlet }}>
      {children}
    </OutletContext.Provider>
  );
};

export const useOutlet = () => useContext(OutletContext);
