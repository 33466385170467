import fetch from "isomorphic-unfetch";

import user from "./user";
import categories from "./categories";
import menuCategories from "./menu_categories";
import blog from "./blog";
import products from "./products";
import cart from "./cart";
import juridicShipping from "./juridic_shipping";
import pages from "./pages";
import orders from "./orders";
import souv from "./souv";
import address from "./address";
import request_products from "./request_products";
import categories_list from "./newApi/categories_list";
import brands from "./brands";

export async function api(url, query, options) {
  const baseUrl = "https://api.goodsbr.com.br";

  const queryUrl = query
    ? `?${Object.entries(query)
        .map(arr => arr.join("="))
        .join("&")}`
    : "";

  const response = await fetch(`${baseUrl}${url}${queryUrl}`, {
    mode: "cors",
    ...options
  });

  const jsonResponse = await response.json();

  return jsonResponse;
}

export const userApi = user;
export const categoriesApi = categories;
export const productsApi = products;
export const cartApi = cart;
export const juridicShippingApi = juridicShipping;
export const menuCategoriesApi = menuCategories;
export const blogApi = blog;
export const pagesApi = pages;
export const ordersApi = orders;
export const souvApi = souv;
export const addressApi = address;
export const brandsApi = brands;
export const requestProductsApi = request_products;

export async function newApi(url, query, options) {
  // const baseUrl = "https://monkfish-app-rwog9.ondigitalocean.app/goods-api-nestjs2";
  const baseUrl = "https://apin.goodsbr.com.br";

  const queryUrl = query
    ? `?${Object.entries(query)
        .map(arr => arr.join("="))
        .join("&")}`
    : "";

  const response = await fetch(`${baseUrl}${url}${queryUrl}`, {
    mode: "cors",
    ...options
  });

  const jsonResponse = await response.json();

  return jsonResponse;
}


export const categoriesToListNewApi = categories_list;
