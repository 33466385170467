import { api } from "./index";

const session = async (email, password, store_token) => {
  const resUser = await api(`/sessions`, null, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ email, password, store_token })
  });


  return resUser;
};

const get = async query => {
  const resUser = await api(`/users`, query);

  return resUser;
};

const create = async user => {
  const resUser = await api(
    `/${user.person_attributes.type_person}-people`,
    null,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ user })
    }
  );

  return resUser;
};

const souv = async user => {
  const resUser = await api(
    `/souv_client`,
    null,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ user })
    }
  );

  return resUser;
};

const change_type = async query => {
  const resChangeType = await api(`/api/users/change_type`, query);

  return resChangeType;
};


export default {
  session,
  get,
  souv,
  create,
  change_type
};
