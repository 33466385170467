import { api } from "./index";

// const show = async (title, query) => {
//   const apiLink = `/blog/${title}`;

//   const resBlog = await api(apiLink, query);

//   const { posts } = resBlog;
//   return { posts };
// };

const show = async (title, query) => {
  const resBlog = await api(`/blog/${title}`, query);

  return resBlog;
};


const index = async () => {
  const resBlog = await api("/blog"); 
  return resBlog;
};

export default {
  show,
  index
};
