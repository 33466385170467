import { api } from "./index";

const get = async query => {
  const resShippingPrice = await api(`/carts/shipping_price`, query);
  return resShippingPrice;
};

const getByState = async query => {
  const resShippingPrice = await api(`/carts/shipping_price_by_state`, query);
  return resShippingPrice;
};


export default {
  get,
  getByState
};
