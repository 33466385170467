import { api } from "./index";

const get = async query => {
  const resCart = await api(`/carts`, query);
  return resCart;
};

const not_order_requirements = async query => {
  const resCart = await api(`/carts/order-requirements`, query);
  return resCart;
};

const cartsJunction = async query => {
  const newCart = await api(`/carts/junction`, query);
  return newCart;
};

const cartCustomerTax = async (query) => {
  const resProductTax = await api(`/carts/cart_customer_tax`, query);

  return resProductTax;
};

const add = async (product_id, amount, amountHeight, amountWidth, query) => {
  const resCart = await api(`/carts`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ product_id, amount, amountHeight, amountWidth })
  });

  return resCart;
};

const remove = async (product_id, query) => {
  const resCart = await api(`/carts/delete`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ product_id })
  });

  return resCart;
};

const change = async (product_id, amount, souv_type, souv_hotel, query) => {
  const resCart = await api(`/carts/change`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ product_id, amount, souv_type, souv_hotel })
  });

  return resCart;
};

const changeSouv = async (product_id, amount, query) => {
  const resCart = await api(`/carts/change`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ product_id, amount })
  });

  return resCart;
};

const clear = async query => {
  const resCart = await api(`/carts/empty`, query);

  return resCart;
};

const paymentMethods = async query => {
  const resPaymentMethods = await api(`/payment-methods`, query);

  return resPaymentMethods;
};

const checkOrder = async (query, orderToken) => {
  const resCheckOrder = await api(`/carts/check_order`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ order_token: orderToken })
  });

  return resCheckOrder;
};

const checkSouvOrder = async (query, orderToken) => {
  const resCheckSouvOrder = await api(`/carts/check_souv_order`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ order_token: orderToken })
  });

  return resCheckSouvOrder;
};

const typesFromDeliver = async query => {
  const resTypesFromDeliver = await api(`/carts/type-from-deliver`, query);

  return resTypesFromDeliver;
};

const itensFromCategories = async (query) => {
  const resTypesFromDeliver = await api(`/carts/items-from-category`, query);
  
  return resTypesFromDeliver;
}

const complete = async (paymentChooser, query) => {
  const resCart = await api(`/carts/complete`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ payment_chooser: paymentChooser })
  });

  return resCart;
};

const completePix = async (query, user) => {
  const resCart = await api(`/carts/complete-pix`, query, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  });

  return resCart;
};

const pay = async ({ token, data, delivery_type, aditionalValue, cart }) => {
  const resCart = await api(
    `/carts/pay`,
    { user_token: token,
      delivery_type: delivery_type,
      aditional_value: aditionalValue,
      cart: cart
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({data: data})
    }
  );

  return resCart;
};

  const pay_souv = async ({ cart_token, address, delivery_selected, aditionalValue, note, user, hotel_id }) => {
    const resCart = await api(
      `/carts/pay_souv`,
      { cart_token: cart_token,
        delivery_selected: delivery_selected,
        aditional_value: aditionalValue,
        note: note,
        user: user,
        hotel_id: hotel_id
      },
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(address)
      }
    );
  
    return resCart;
  };
  
const updateAddress = async ({ token, address })  => {
  const resCart = await api(
    `/carts/update_address`,
    { user_token: token
    },
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(address)
    }
  );

  return resCart;
};

export default {
  get,
  add,
  remove,
  change,
  changeSouv,
  clear,
  paymentMethods,
  complete,
  completePix,
  cartsJunction,
  cartCustomerTax,
  pay,
  pay_souv,
  updateAddress,
  not_order_requirements,
  typesFromDeliver,
  itensFromCategories,
  checkOrder,
  checkSouvOrder
};
