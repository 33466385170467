import { useEffect } from "react";
import Router from "next/router";
import nextCookie from "next-cookies";
import cookie from "js-cookie";

import { userApi, cartApi } from "../services/api";

export const login = async (token, setUser, setCart, cart_token) => {
  cookie.set("token", token, { expires: 30 });

  const fetchedUser = token ? await userApi.get({ user_token: token }) : null;
  const cartJunction = cart_token ? cartApi.cartsJunction({ user_token: token, cart_token: cart_token }) : null;
  const fetchedCart = token ? await cartApi.get({ user_token: token, cart_token: cart_token }) : null;

  setUser(fetchedUser);
  setCart(fetchedCart);

  Router.push("/");
};

export const auth = ctx => {
  const { token } = nextCookie(ctx);

  /*
   * If `ctx.req` is available it means we are on the server.
   * Additionally if there's no token it means the user is not logged in.
   */
  if (ctx.req && !token) {
    ctx.res.writeHead(302, { Location: "/login" });
    ctx.res.end();
  }

  // We already checked for server. This should only happen on client.
  if (!token) {
    Router.push("/login");
  }

  return token;
};

export const logout = setUser => {
  cookie.remove("token");

  if (process.browser) {
    // to support logging out from all windows
    window.localStorage.setItem("logout", Date.now());

    if (setUser) {
      setUser(null);
    }

    Router.push("/");
  }
};

export const withAuthSync = WrappedComponent => {
  const Wrapper = props => {
    const syncLogout = event => {
      if (event.key === "logout") {
        console.log("logged out from storage!");
        Router.push("/login");
      }
    };

    useEffect(() => {
      window.addEventListener("storage", syncLogout);

      return () => {
        window.removeEventListener("storage", syncLogout);
        window.localStorage.removeItem("logout");
      };
    }, [null]);

    return <WrappedComponent {...props} />;
  };

  Wrapper.getInitialProps = async ctx => {
    const token = auth(ctx);

    const componentProps =
      WrappedComponent.getInitialProps &&
      (await WrappedComponent.getInitialProps(ctx));

    return { ...componentProps, token };
  };

  return Wrapper;
};
